import React from "react";
import './Noise.css';

function Noise() {
  return(
    <div className="Noise">
    </div>
  )
}
export default Noise
